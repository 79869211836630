.dashboard {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.dashboard__rightSide {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow-x: hidden; }

.dashboard__content {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-top: calc(2 * var(--gutter));
  padding-bottom: calc(2 * var(--gutter));
  overflow: auto; }

.profileMenu {
  cursor: pointer;
  height: 40px;
  padding: 13px 20px;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow: hidden; }
  .profileMenu.mini {
    padding: 13px 15px !important; }

.profileMenu__avatar {
  margin: 0 15px 0 0 !important;
  height: 40px !important;
  width: 40px !important;
  line-height: 40px;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 50%;
  -webkit-justify-content: center;
          justify-content: center; }
  .profileMenu__avatar.mini {
    margin-right: 0px !important; }

.profileMenu__details {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.profileMenu__username {
  color: var(--linkColor);
  font-size: 14px;
  white-space: nowrap;
  margin: 0 0 2px;
  font-weight: 500;
  max-width: 170px;
  text-transform: capitalize;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.profileMenu__arrow {
  color: var(--linkColor);
  font-size: 14px;
  white-space: nowrap;
  margin: 0 0 2px;
  font-weight: 500;
  vertical-align: middle;
  width: 1.2857em !important;
  font-size: 17px !important;
  text-align: center; }

.sidebar {
  height: 100%;
  width: 280px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-right: 1px solid var(--textColor--light);
  box-sizing: border-box; }
  .sidebar.mini {
    width: auto; }

.sidebar__content {
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow: hidden;
  position: relative; }
  .sidebar__content:hover {
    overflow: auto; }
  .sidebar__content > div:nth-child(3) {
    pointer-events: none; }

.sidebar__list {
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin: 0; }

.sidebar__listItem {
  display: list-item;
  text-align: -webkit-match-parent;
  border-left: 4px solid transparent;
  width: 100%;
  box-sizing: border-box; }
  .sidebar__listItem.mini a {
    padding: 16px 14px 16px 14px; }
  .sidebar__listItem.mini svg {
    font-size: 2rem !important;
    margin-right: 0; }
  .sidebar__listItem a {
    display: block;
    font-size: 14px;
    min-width: inherit;
    padding: 16px 20px;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: var(--textColor);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none; }
    .sidebar__listItem a svg {
      margin-right: 10px; }
  .sidebar__listItem:hover a {
    color: var(--linkColor); }
  .sidebar__listItem.active {
    border-color: var(--linkColor); }
    .sidebar__listItem.active a {
      color: var(--linkColor); }

.sidebar__tooltip {
  font-size: 1.3rem;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 500; }

.header {
  height: 50px;
  padding: 10px 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  background: -webkit-linear-gradient(left, var(--primaryColor) 0%, var(--secondaryColor) 120%);
  background: linear-gradient(to right, var(--primaryColor) 0%, var(--secondaryColor) 120%);
  color: #fff; }

.header__searchBox__container {
  background-color: transparent;
  margin-left: 50px;
  width: 380px; }

.header__searchBox__input {
  background-color: #0000001f !important;
  color: #fff !important;
  border: none !important;
  -webkit-transition: background-color 0.15s ease-in-out, background-color 0.15s ease-in-out !important;
  transition: background-color 0.15s ease-in-out, background-color 0.15s ease-in-out !important; }
  .header__searchBox__input:focus {
    background: #fff !important;
    color: var(--textColor) !important;
    -webkit-transition: background-color 0.15s ease-in-out, background-color 0.15s ease-in-out !important;
    transition: background-color 0.15s ease-in-out, background-color 0.15s ease-in-out !important; }
  .header__searchBox__input:focus + .header__searchBox__button {
    color: var(--textColor);
    -webkit-transition: background-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-color 0.15s ease-in-out; }

.header__searchBox__button {
  color: #fff; }

.header__logo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 75%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.header__logo__image {
  height: 100%;
  width: auto; }

.header__right {
  margin-left: auto; }
  .header__right div {
    display: -webkit-flex;
    display: flex; }

.header__iconButton {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer; }

.header__icon {
  font-size: 2em !important;
  color: #fff;
  margin-right: 5px; }

.searchBox {
  display: inline-block; }

.searchBox__input {
  padding: 10px;
  border: 2px solid var(--primaryColor--light);
  box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.1);
  outline-offset: 0;
  outline-color: var(--secondaryColor);
  background-color: #fff;
  padding-right: 35px; }

.searchBox__button {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  height: 100%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  outline: none;
  cursor: pointer; }

.searchBox__icon {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
  background-color: transparent;
  color: inherit;
  border: 0 none;
  height: 36px;
  width: 30px; }

.searchBox__group {
  background-color: transparent;
  position: relative; }

.SlideWrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 665;
}

.SlideWrapper--close {
    -webkit-animation: undim 0.4s ease-out forwards;
            animation: undim 0.4s ease-out forwards;
}

.SlideWrapper--open {
    -webkit-animation: dim 0.3s ease-out forwards;
            animation: dim 0.3s ease-out forwards;
}

.SlideModal {
    -webkit-transition: width 0.3s ease-out;
    transition: width 0.3s ease-out;
}

.h-textRight {
    text-align: right;
}

.h-overflowHidden {
    overflow: hidden !important;
}

.h-overflowAuto {
    overflow: auto !important;
}

.h-displayFlex {
    display: -webkit-flex !important;
    display: flex !important;
}

.h-flexCol {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
}

.h-flexSpaceBetween {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
}

.SlideModal {
    position: fixed;
    /*right: -50%;*/
    top: 0;
    bottom: 0;
    width: 50%;
    min-width: 320px;
    background-color: #efefef;
    z-index: 666;
}

.SlideModal--right {
    right: -50%;
}

.SlideModal--left {
    left: -50%;
}

.SlideModal--open.SlideModal--right {
    -webkit-animation: slideIn--right 0.3s ease-out forwards;
            animation: slideIn--right 0.3s ease-out forwards;
}

.SlideModal--open.SlideModal--left {
    -webkit-animation: slideIn--left 0.3s ease-out forwards;
            animation: slideIn--left 0.3s ease-out forwards;
}

@media (max-width: 520px) {
    .SlideModal--open {
        width: 100%;
    }
}

.SlideModal--close.SlideModal--right {
    -webkit-animation: slideOut--right 0.3s ease-out forwards;
            animation: slideOut--right 0.3s ease-out forwards;
}

.SlideModal--close.SlideModal--left {
    -webkit-animation: slideOut--left 0.3s ease-out forwards;
            animation: slideOut--left 0.3s ease-out forwards;
}

.SlideModal__header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.2);
    z-index: 667;
}

.SlideModal__footer {
    box-shadow: 0 -3px 2px 0 rgba(0, 0, 0, 0.2);
}

.SlideModal__title {
    padding-left: 15px;
    color: #000;
}

@-webkit-keyframes dim {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@keyframes dim {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@-webkit-keyframes undim {
    from {
        background-color: rgba(0, 0, 0, 0.5);
    }
    to {
        background-color: transparent;
    }
}

@keyframes undim {
    from {
        background-color: rgba(0, 0, 0, 0.5);
    }
    to {
        background-color: transparent;
    }
}

@-webkit-keyframes slideIn--right {
    0% {
        right: calc(-100%);
    }
    100% {
        right: 0;
    }
}

@keyframes slideIn--right {
    0% {
        right: calc(-100%);
    }
    100% {
        right: 0;
    }
}

@-webkit-keyframes slideOut--right {
    0% {
        right: 0;
    }
    100% {
        right: calc(-100%);
    }
}

@keyframes slideOut--right {
    0% {
        right: 0;
    }
    100% {
        right: calc(-100%);
    }
}

@-webkit-keyframes slideIn--left {
    0% {
        left: calc(-100%);
    }
    100% {
        left: 0;
    }
}

@keyframes slideIn--left {
    0% {
        left: calc(-100%);
    }
    100% {
        left: 0;
    }
}

@-webkit-keyframes slideOut--left {
    0% {
        left: 0;
    }
    100% {
        left: calc(-100%);
    }
}

@keyframes slideOut--left {
    0% {
        left: 0;
    }
    100% {
        left: calc(-100%);
    }
}

.slidingPane__header {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.slidingPane__title {
  margin-left: auto;
  margin-right: auto;
  padding-right: 54px; }

.slidingPane__close {
  padding: 15px;
  color: var(--textColor--dark);
  cursor: pointer; }

.slidingPane__content {
  padding: 15px;
  display: -webkit-flex;
  display: flex; }

.SlideModal {
  background-color: var(--backgroundColor);
  width: 500px; }

.appTile {
  width: 25%;
  border: 2px solid transparent;
  padding: 15px;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-decoration: none;
  box-sizing: border-box; }
  .appTile:hover {
    border-color: #cecece;
    border-radius: 4px; }

.appTile__img {
  width: 100%;
  height: auto; }

.appTile__name {
  margin: 0;
  text-align: center;
  color: var(--textColor--dark);
  font-weight: bold; }

.signInPanel__container {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex; }

.signInPanel__logo {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  max-width: 93%;
  max-height: 150px; }

.signInPanel {
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  box-shadow: -6px 4px 15px 8px rgba(0, 0, 0, 0.15);
  width: 800px;
  max-width: 95%;
  height: 400px; }

.signInPanel__section {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.signInPanel__right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: -webkit-linear-gradient(left, var(--primaryColor) 0%, var(--secondaryColor) 120%);
  background: linear-gradient(to right, var(--primaryColor) 0%, var(--secondaryColor) 120%); }

@charset "UTF-8";
@font-face {
  font-family: 'Notification';
  src: url(/static/media/notification.c0d3c94c.eot);
  src: url(/static/media/notification.c0d3c94c.eot?#iefixs3g3t9) format("embedded-opentype"), url(/static/media/notification.651771e1.woff) format("woff"), url(/static/media/notification.0b4ac1dc.ttf) format("truetype"), url(/static/media/notification.5bee74ca.svg#notification) format("svg");
  font-weight: normal;
  font-style: normal;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover, .notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: 'Notification';
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  background-color: #2f96b4;
}

.notification-info:before {
  content: "\F05A";
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  content: "\F058";
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: "\F06A";
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: "\F057";
}

.notification-container {
  padding-bottom: 5px; }

.notification-message {
  color: var(--textColor);
  word-break: break-word; }
  .notification-message h4 {
    color: var(--textColor) !important;
    font-size: 15px !important; }
  .notification-message .message {
    font-size: 14px !important; }

.notification {
  background-color: #fff;
  border-left: 5px solid transparent;
  box-sizing: border-box; }

.notification-info {
  border-color: var(--secondaryColor); }
  .notification-info:before {
    color: var(--secondaryColor); }

.notification-success {
  border-color: var(--successColor); }
  .notification-success:before {
    color: var(--successColor); }

.notification-error {
  border-color: var(--errorColor); }
  .notification-error:before {
    color: var(--errorColor); }

.notification-warning {
  border-color: var(--warningColor); }
  .notification-warning:before {
    color: var(--warningColor); }

.googleButton {
  border-radius: 4px !important;
  padding: 5px 15px !important;
  box-shadow: -3px 2px 12px 3px rgba(0, 0, 0, 0.3) !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
  outline: none !important;
  box-sizing: border-box !important; }
  .googleButton:hover {
    background-color: #dadada !important; }
    .googleButton:hover div {
      background-color: #dadada !important; }
  .googleButton span {
    font-size: 16px !important;
    font-weight: bold !important; }

.card {
  border: solid 1px var(--textColor--light);
  box-sizing: border-box;
  box-shadow: none; }

.loading__container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.loading__img {
  height: 6rem;
  width: 6rem;
  padding: 10px; }

.recentActivity__row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 20px; }

.recentActivity__link {
  font-weight: bold;
  margin-right: 5px; }

.recentActivity__date {
  margin-top: 6px;
  font-size: 0.9rem; }

.recentActivity__icon {
  margin-left: 1.5rem;
  margin-right: 2rem; }

.recentActivity__information__row {
  display: inline-block !important; }

.iconCircle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .iconCircle svg {
    font-size: 1.8rem !important; }

.statBox__icon {
  margin-left: 1rem;
  margin-right: 1rem; }

.statBox__value {
  color: var(--textColor--dark);
  font-size: 1.7rem;
  font-weight: bold; }

.statBox__name {
  font-size: 1.2rem; }

.statBox__content {
  padding-top: 18px !important;
  padding-bottom: 18px !important; }

button.button {
  background-color: var(--linkColor);
  color: #fff !important;
  text-transform: none;
  padding: 8px 30px;
  border-radius: 50px;
  max-height: 40px;
  font-weight: bold; }
  button.button:hover {
    background-color: var(--linkColor--hover); }
  button.button:disabled {
    opacity: 0.7; }

.simpleModal {
  overflow-y: auto; }
  .simpleModal > div:first-of-type {
    opacity: 0 !important;
    z-index: -1;
    pointer-events: none; }
  .simpleModal > div:last-of-type:before {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(left, var(--primaryColor) 0%, var(--secondaryColor) 100%) !important;
    background: linear-gradient(to right, var(--primaryColor) 0%, var(--secondaryColor) 100%) !important;
    -webkit-transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    opacity: 0.85;
    z-index: 0;
    display: block;
    content: ''; }
  .simpleModal > div:last-of-type > div:first-of-type {
    overflow-y: visible;
    max-height: none;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.2) !important;
    z-index: 2; }

.dataModal > div:last-of-type > div:first-of-type {
  padding: 50px;
  padding-bottom: 0px;
  width: 700px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0; }

.dataModal__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0px 5px; }

.dataModal__headerText {
  font-weight: bold; }

.dataModal__exit {
  margin-left: auto;
  font-size: 28px !important;
  cursor: pointer; }

.dataModal__tabContainer {
  margin-top: 15px;
  padding: 0px 5px 10px 5px;
  width: 100%;
  border-bottom: 1px solid var(--primaryColor--light);
  box-sizing: border-box; }
  .dataModal__tabContainer .dataModal__tab:not(:first-child) {
    margin-left: 25px; }

.dataModal__tab {
  color: var(--textColor--dark);
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  padding-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .dataModal__tab.active {
    border-color: var(--secondaryColor); }

.dataModal__body {
  padding: 20px 5px; }

.dataModal__button {
  margin-left: auto !important;
  margin-top: calc(2 * var(--gutter)) !important; }

.deleteModal > div:last-of-type > div:first-of-type {
  padding: 50px;
  width: 700px;
  max-width: 98%;
  margin-left: 10px;
  margin-right: 10px; }

.deleteModal__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.deleteModal__headerText {
  font-weight: bold; }

.deleteModal__exit {
  margin-left: auto;
  font-size: 28px !important;
  cursor: pointer; }

.deleteModal__button {
  margin-left: auto !important;
  margin-top: 25px !important;
  background-color: var(--errorColor) !important; }
  .deleteModal__button:hover {
    background-color: var(--errorColor) !important;
    -webkit-filter: brightness(140%);
            filter: brightness(140%); }

.input__container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px; }

.input__label {
  text-transform: capitalize;
  color: var(--textColor--dark);
  font-weight: 600;
  margin-bottom: 10px; }

.input {
  padding: 10px;
  border: 2px solid var(--primaryColor--light);
  box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.1);
  outline-offset: 0;
  outline-color: var(--secondaryColor);
  background-color: #fff;
  color: var(--textColor--dark); }
  .input.error {
    border-color: var(--errorColor); }
  .input:disabled {
    background-color: #ebebeb;
    color: #545454; }

.input__required {
  color: var(--errorColor); }

.input__infoApplied {
  padding-right: 25px; }

.input__innerContainer {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }
  .input__innerContainer:hover > .input__infoWrapper {
    display: -webkit-flex;
    display: flex; }

.input__infoWrapper {
  display: none;
  position: absolute;
  right: 0;
  padding: 5px; }

.input__info {
  color: var(--textColor); }
  .input__info:hover::after, .input__info:hover::before {
    opacity: 1; }

/* Add this attribute to the element that needs a tooltip */
[data-tip] {
  z-index: 2;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tip]:before,
[data-tip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; }

/* Position tooltip above the element */
[data-tip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  border-radius: 3px;
  background-color: #000;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  content: attr(data-tip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid rgba(51, 51, 51, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tip]:hover:before,
[data-tip]:hover:after {
  visibility: visible;
  opacity: 1; }

.select {
  border-radius: 0 !important; }
  .select.error .select__control {
    border-color: var(--errorColor) !important; }

.select__control {
  border-radius: 0 !important;
  border-color: transparent !important;
  padding: 1px !important;
  border: 2px solid var(--primaryColor--light) !important;
  outline-offset: 0 !important;
  outline-color: var(--secondaryColor) !important;
  background-color: #fff !important;
  box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.1) !important; }

.select__control--is-disabled {
  border-radius: 0 !important;
  padding: 1px !important;
  border: 2px solid var(--primaryColor--light) !important;
  background-color: #ebebeb !important; }

.select__placeholder {
  color: var(--textColor--dark) !important; }

.select__single-value {
  color: var(--textColor--dark) !important; }

.select__single-value--is-disabled {
  color: #545454 !important; }


.selectRow__iconColumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  padding: var(--gutter); }

.selectRow__addIcon {
  font-size: 2.5rem !important;
  color: var(--textColor);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .selectRow__addIcon:hover {
    color: var(--primaryColor); }

.selectRow__deleteIcon {
  font-size: 2.5rem !important;
  color: var(--textColor);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .selectRow__deleteIcon:hover {
    color: var(--errorColor); }

.textArea {
  resize: none; }

.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s; }

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #4D4D4D;
  -webkit-filter: brightness(80%);
          filter: brightness(80%); }

.react-toggle--checked .react-toggle-track {
  background-color: var(--successColor); }

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--successColor);
  -webkit-filter: brightness(80%);
          filter: brightness(80%); }

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; }

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; }

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; }

.react-toggle--checked .react-toggle-track-x {
  opacity: 0; }

.react-toggle-thumb {
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: var(--successColor); }

.toggle__container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-flex: 1 1;
          flex: 1 1; }

.toggle__label {
  text-transform: capitalize;
  color: var(--textColor--dark);
  font-weight: 600;
  margin-bottom: 10px; }

.toggle__innerContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center; }

.table {
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  position: relative; }
  .table > div:first-child > div:nth-child(3) {
    min-height: 561px;
    max-height: 561px;
    position: relative; }
  .table tr svg {
    color: #0000008a; }
  .table .th {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.table-hidden > div:first-child > div:nth-child(3) {
  visibility: hidden !important; }

.datatables-noprint {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.noScrollBar > div:first-child > div:nth-child(3)::-webkit-scrollbar {
  display: none; }

.loadingOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.7);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 200; }

.customToolbarSelect {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 2em;
  color: var(--textColor); }

.operationButton {
  color: #0000008a;
  padding: 0.2em;
  cursor: pointer; }

.operationButton__container {
  display: -webkit-flex;
  display: flex; }
  .operationButton__container svg:not(:last-child) {
    margin-right: 10px; }



.numberFilter__column {
  padding: 16px !important; }

.numberFilter__select {
  width: 100%;
  color: var(--textColor--dark); }

.numberFilter__buttonRow {
  width: 100%; }

.numberFilter__input {
  width: 100%; }

.numberFilter__button {
  font-size: 100% !important;
  padding: 4px 26px !important;
  margin: var(--gutter) !important; }

.numberFilter__clearButton {
  background-color: var(--textColor) !important; }

.numberFilter__clearButton:hover {
  background-color: #606a74 !important; }

.wordFilter__column {
  padding: 16px !important; }

.wordFilter__select {
  width: 100%;
  color: var(--textColor--dark); }

.wordFilter__buttonRow {
  width: 100%; }

.wordFilter__input {
  width: 100%; }

.wordFilter__button {
  font-size: 100% !important;
  padding: 4px 26px !important;
  margin: var(--gutter) !important; }

.wordFilter__clearButton {
  background-color: var(--textColor) !important; }

.wordFilter__clearButton:hover {
  background-color: #606a74 !important; }

.radioFilter__column {
  padding: 16px !important; }

.radioFilter__checkboxList {
  width: 100%;
  color: var(--textColor--dark); }

.radioFilter__buttonRow {
  width: 100%; }

.radioFilter__radio {
  padding: 4px !important; }

.radioFilter__button {
  font-size: 100% !important;
  padding: 4px 26px !important;
  margin: var(--gutter) !important; }

.radioFilter__clearButton {
  background-color: var(--textColor) !important; }

.radioFilter__clearButton:hover {
  background-color: #606a74 !important; }

.noTableScrollBar > div:first-child > div:nth-child(3)::-webkit-scrollbar {
  display: none; }

.bugFooter {
  background-color: #fff;
  padding: 0 calc(2 * var(--gutter));
  border-top: solid 1px var(--textColor--light);
  box-sizing: border-box; }

.PresetDateRangePicker_panel {
  padding: 0 22px 11px
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer
}
.PresetDateRangePicker_button:active {
  outline: 0
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff
}
.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.SingleDatePickerInput__rtl {
  direction: rtl
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2
}
.SingleDatePickerInput__block {
  display: block
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.SingleDatePicker {
  position: relative;
  display: inline-block
}
.SingleDatePicker__block {
  display: block
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.SingleDatePicker_picker__rtl {
  direction: rtl
}
.SingleDatePicker_picker__directionLeft {
  left: 0
}
.SingleDatePicker_picker__directionRight {
  right: 0
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2
}
.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a
}
.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}
.CalendarDay:active {
  outline: 0
}
.CalendarDay__defaultCursor {
  cursor: default
}
.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848
}
.CalendarDay__outside:hover {
  border: 0
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848
}
.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__last_in_range,
.CalendarDay__last_in_range:hover {
  border-style: solid
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate
}
.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: top;
  caption-side: initial
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}
.CalendarMonthGrid__animating {
  z-index: 1
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}
.CalendarMonthGrid__vertical {
  margin: 0 auto
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden
}
.DayPickerNavigation {
  position: relative;
  z-index: 2
}
.DayPickerNavigation__horizontal {
  height: 0
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2
}
.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:active {
  background: 0 0
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848
}
.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left
}
.DayPicker__horizontal {
  background: #fff
}
.DayPicker__verticalScrollable {
  height: 100%
}
.DayPicker__hidden {
  visibility: hidden
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}
.DayPicker_portal__vertical {
  position: static;
  position: initial
}
.DayPicker_focusRegion {
  outline: 0
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_weekHeaders {
  position: relative
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left
}
.DayPicker_weekHeader__vertical {
  left: 50%
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}
.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out
}
.DayPicker_transitionContainer__vertical {
  width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle
}
.DateInput__small {
  width: 97px
}
.DateInput__block {
  width: 100%
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb
}
.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .2px;
  padding: 7px 7px 5px
}
.DateInput_input__regular {
  font-weight: auto
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2
}
.DateInput_fangShape {
  fill: #fff
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block
}
.DateRangePickerInput__disabled {
  background: #f2f2f2
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.DateRangePickerInput__rtl {
  direction: rtl
}
.DateRangePickerInput__block {
  display: block
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}
.DateRangePickerInput_clearDates__small {
  padding: 6px
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.DateRangePicker {
  position: relative;
  display: inline-block
}
.DateRangePicker__block {
  display: block
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.DateRangePicker_picker__rtl {
  direction: rtl
}
.DateRangePicker_picker__directionLeft {
  left: 0
}
.DateRangePicker_picker__directionRight {
  right: 0
}
.DateRangePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.presetDateRangePicker__panel {
  padding: 0 22px 11px 22px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.presetDateRangePicker__select {
  width: 50%;
  margin-top: 0px;
  margin-bottom: 4px; }

.PresetDateRangePicker_panel {
  padding: 0 22px 11px; }

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer; }

.PresetDateRangePicker_button:active {
  outline: 0; }

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: var(--primaryColor); }

.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff; }

.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb; }

.SingleDatePickerInput__rtl {
  direction: rtl; }

.SingleDatePickerInput__disabled {
  background-color: #f2f2f2; }

.SingleDatePickerInput__block {
  display: block; }

.SingleDatePickerInput__showClearDate {
  padding-right: 30px; }

.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%; }

.SingleDatePickerInput_clearDate__small {
  padding: 6px; }

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden; }

.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle; }

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px; }

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px; }

.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle; }

.SingleDatePicker {
  position: relative;
  display: inline-block; }

.SingleDatePicker__block {
  display: block; }

.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute; }

.SingleDatePicker_picker__rtl {
  direction: rtl; }

.SingleDatePicker_picker__directionLeft {
  left: 0; }

.SingleDatePicker_picker__directionRight {
  right: 0; }

.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff; }

.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2; }

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none; }

.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd; }

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  outline: 0; }

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0; }

.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2; }

.DayPickerKeyboardShortcuts_show::before {
  content: '';
  display: block;
  position: absolute; }

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid var(--primaryColor);
  bottom: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid var(--linkColor); }

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid var(--primaryColor);
  top: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489; }

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0; }

.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid var(--primaryColor);
  top: 0;
  left: 0; }

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489; }

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute; }

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px; }

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px; }

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px; }

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left;
  z-index: 4; }

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0; }

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px; }

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2; }

.DayPickerKeyboardShortcuts_close:active {
  outline: 0; }

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd; }

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a; }

.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center; }

.CalendarDay:active {
  outline: 0; }

.CalendarDay__defaultCursor {
  cursor: default; }

.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff; }

.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit; }

.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit; }

.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848; }

.CalendarDay__outside:hover {
  border: 0; }

.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd; }

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd; }

.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848; }

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848; }

.CalendarDay__selected_span {
  background: var(--secondaryColor);
  border: 1px double #e4e7e7;
  color: #fff; }

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: var(--linkColor);
  border: 1px double var(--linkColor);
  color: #fff; }

.CalendarDay__last_in_range,
.CalendarDay__last_in_range:hover {
  border-style: solid; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--primaryColor);
  border: 1px double var(--primaryColor);
  color: #fff; }

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: var(--secondaryColor);
  border: 1px solid white;
  color: white; }

.CalendarDay__hovered_span:active {
  background: var(--secondaryColor);
  border: 1px solid white;
  color: white; }

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a; }

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd; }

.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0; }

.CalendarMonth_verticalSpacing {
  border-collapse: separate; }

.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: top;
  caption-side: initial; }

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px; }

.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0;
  z-index: 2; }

.CalendarMonthGrid__animating {
  z-index: 1; }

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px; }

.CalendarMonthGrid__vertical {
  margin: 0 auto; }

.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll; }

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%; }

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none; }

.CalendarMonthGrid_month__hidden {
  visibility: hidden; }

.DayPickerNavigation {
  position: relative;
  z-index: 3; }

.DayPickerNavigation__horizontal {
  height: 0; }

.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0; }

.DayPickerNavigation__verticalScrollableDefault {
  position: relative; }

.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0; }

.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575; }

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4; }

.DayPickerNavigation_button__default:active {
  background: #f2f2f2; }

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2; }

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2; }

.DayPickerNavigation_button__disabled:active {
  background: 0 0; }

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px; }

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px; }

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px; }

.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%; }

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0; }

.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%; }

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block; }

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848; }

.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2; }

.DayPicker {
  background: #fff;
  position: relative;
  text-align: left; }

.DayPicker__horizontal {
  background: #fff; }

.DayPicker__verticalScrollable {
  height: 100%; }

.DayPicker__hidden {
  visibility: hidden; }

.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px; }

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%; }

.DayPicker_portal__vertical {
  position: static;
  position: initial; }

.DayPicker_focusRegion {
  outline: 0; }

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top; }

.DayPicker_weekHeaders {
  position: relative;
  z-index: 3; }

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px; }

.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 10002;
  text-align: left; }

.DayPicker_weekHeader__vertical {
  left: 50%; }

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center; }

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px; }

.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center; }

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px; }

.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out; }

.DayPicker_transitionContainer__vertical {
  width: 100%; }

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll; }

.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle; }

.DateInput__small {
  width: 97px; }

.DateInput__block {
  width: 100%; }

.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb; }

.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0; }

.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px; }

.DateInput_input__regular {
  font-weight: auto; }

.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid var(--primaryColor);
  border-left: 0; }

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
  position: absolute;
  width: 100%;
  height: 100%; }

.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 10001;
  display: none; }

.DateInput_fangShape {
  fill: #fff; }

.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent; }

.DateRangePickerInput {
  background-color: #fff;
  display: inline-block; }

.DateRangePickerInput__disabled {
  background: #f2f2f2; }

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb; }

.DateRangePickerInput__rtl {
  direction: rtl; }

.DateRangePickerInput__block {
  display: block; }

.DateRangePickerInput__showClearDates {
  padding-right: 30px; }

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848; }

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px; }

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.DateRangePickerInput_clearDates__small {
  padding: 6px; }

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%; }

.DateRangePickerInput_clearDates__hide {
  visibility: hidden; }

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle; }

.DateRangePickerInput_clearDates_svg__small {
  height: 9px; }

.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px; }

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle; }

.DateRangePicker {
  position: relative;
  display: inline-block;
  margin: 5px;
  margin-right: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12), 0 0px 5px rgba(0, 0, 0, 0.24); }
  .DateRangePicker * {
    box-sizing: border-box; }

.DateRangePicker__block {
  display: block; }

.DateRangePicker_picker {
  z-index: 10000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #fff;
  position: absolute; }

.DateRangePicker_picker__rtl {
  direction: rtl; }

.DateRangePicker_picker__directionLeft {
  left: 0; }

.DateRangePicker_picker__directionRight {
  right: 0; }

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff; }

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2; }

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none; }

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd; }

.label {
  text-transform: capitalize;
  color: var(--textColor--dark);
  font-weight: 600;
  margin-bottom: 10px; }

.label__required {
  color: var(--errorColor); }

.errorText {
  color: var(--errorColor);
  font-size: 0.9rem; }

.json__noCollapsibleRoot .icon-container {
  display: none !important; }

.json__disabled {
  background: #ebebeb; }

.react-json-view {
  overflow: auto; }

.radio--checked {
  color: var(--linkColor) !important; }

.radioList {
  margin: 5px; }

.radioList__radioGroup {
  margin: 2px 0px; }

.radioButton:checked {
  color: lightblue; }

.checkBox--checked {
  color: var(--linkColor) !important; }

.checkboxList {
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.checkboxList__columnWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.checkboxList__rowWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }
  .checkboxList__rowWrapper label {
    margin-left: 0; }
    .checkboxList__rowWrapper label > span:first-child {
      margin-right: 10px; }

.checkboxList__right {
  margin-left: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.checkboxList__arrow {
  margin-left: calc(2 * var(--gutter));
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer; }

.checkboxList__arrow--open {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.checkboxList__arrow--closed {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.checkboxList__expandableRow {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.checkboxList__expandableRow--open {
  max-height: 500px;
  overflow-y: scroll;
  display: block; }

.checkboxList__expandableRow--closed {
  max-height: 0;
  display: none; }

.progressBar {
  background-color: var(--linkColor);
  height: 6px;
  width: 100%; }


.chip {
  font-weight: bold;
  border: solid 1px transparent !important;
  font-size: 12px !important; }

.chip--success {
  border-color: var(--successColor--dark) !important;
  color: var(--successColor--dark) !important;
  background-color: var(--successColor--light) !important; }

.chip--danger {
  border-color: var(--errorColor--dark) !important;
  color: var(--errorColor--dark) !important;
  background-color: var(--errorColor--light) !important; }

.chip--warning {
  border-color: var(--warningColor--dark) !important;
  color: var(--warningColor--dark) !important;
  background-color: var(--warningColor--light) !important; }

.chip--default {
  border-color: var(--textColor--dark) !important;
  color: var(--textColor--dark) !important;
  background-color: var(--textColor--light) !important; }

.fieldMapColumn__container {
  margin: var(--gutter);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.fieldMapColumn__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-transition: background-color 0.1s ease;
  transition: background-color 0.1s ease; }

.fieldMapColumn__header--notDraggable {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.fieldMapColumn__inputWrapper {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.fieldMapColumn__title {
  color: var(--textColor--dark);
  display: block;
  font-size: 1.5em;
  font-weight: bold; }

.fieldMapColumn__titleInputContainer {
  -webkit-transition: background-color ease 0.2s;
  transition: background-color ease 0.2s;
  padding: calc(4 * var(--gutter)) var(--gutter);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  margin: 0px; }

.fieldMapColumn__label {
  text-transform: capitalize;
  color: var(--textColor--dark);
  font-weight: 600;
  margin: 0; }

.fieldMapColumn__labelWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: var(--gutter);
  border: var(--gutter);
  padding-bottom: 0;
  padding-top: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.fieldMapColumn__iconColumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  padding: var(--gutter); }
  .fieldMapColumn__iconColumn.fieldMapRow__iconColumn__iconEnd {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-bottom: 17px; }

.fieldMapColumn__addIcon {
  font-size: 2.5rem !important;
  color: var(--textColor);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .fieldMapColumn__addIcon:hover {
    color: var(--primaryColor); }

.fieldMapColumn__dragIcon {
  font-size: 2.5rem !important;
  color: var(--textColor); }
  .fieldMapColumn__dragIcon:hover {
    color: var(--linkColor); }

.fieldMapColumn__iconNotVisible {
  visibility: hidden;
  height: 1px !important; }

.fieldMapColumn__deleteIcon {
  font-size: 2.5rem !important;
  color: var(--textColor);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .fieldMapColumn__deleteIcon:hover {
    color: var(--errorColor); }

.fieldMapRow__iconColumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  padding: var(--gutter); }
  .fieldMapRow__iconColumn.fieldMapRow__iconColumn__iconEnd {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-bottom: 17px; }

.fieldMapRow__addIcon {
  font-size: 2.5rem !important;
  color: var(--textColor);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .fieldMapRow__addIcon:hover {
    color: var(--primaryColor); }

.fieldMapRow__deleteIcon {
  font-size: 2.5rem !important;
  color: var(--textColor);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .fieldMapRow__deleteIcon:hover {
    color: var(--errorColor); }

.fieldMapRow__iconColumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  padding: var(--gutter); }
  .fieldMapRow__iconColumn.fieldMapRow__iconColumn__iconEnd {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-bottom: 17px; }

.fieldMapRow__addIcon {
  font-size: 2.5rem !important;
  color: var(--textColor);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .fieldMapRow__addIcon:hover {
    color: var(--primaryColor); }

.fieldMapRow__dragIcon {
  font-size: 2.5rem !important;
  color: var(--textColor);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .fieldMapRow__dragIcon.fieldMapRow__dragIcon--notVisible {
    visibility: hidden; }
  .fieldMapRow__dragIcon:hover {
    color: var(--linkColor); }

.fieldMapRow--disableInteraction {
  -webkit-transform: none !important;
          transform: none !important;
  display: none !important; }

.fieldMapRow__deleteIcon {
  font-size: 2.5rem !important;
  color: var(--textColor);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .fieldMapRow__deleteIcon:hover {
    color: var(--errorColor); }

.fieldMapList__wrapper {
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  opacity: 1;
  padding: var(--gutter);
  border: var(--gutter);
  padding-bottom: 0;
  -webkit-transition: background-color 0.1s ease, opacity 0.1s ease;
  transition: background-color 0.1s ease, opacity 0.1s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.fieldMapList__wrapper--isDraggingOver {
  background-color: var(--primaryColor--light); }

.fieldMapList__wrapper--isDropDisabled {
  opacity: 0.5; }

.fieldMapList__dropZone {
  /* stop the list collapsing when empty */
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  margin-bottom: var(--gutter); }

.dropDown {
  font-size: 15px; }
  .dropDown li {
    display: -webkit-flex;
    display: flex; }
    .dropDown li a {
      color: inherit;
      text-decoration: none;
      display: -webkit-flex;
      display: flex;
      text-align: center; }
      .dropDown li a svg {
        font-size: 20px !important;
        margin-right: 5px; }
  .dropDown div:last-child {
    width: auto !important; }

.dropDownIcon {
  font-size: 14px;
  margin-right: 2px; }

.breadcrumbs {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.breadcrumbs__link {
  color: inherit;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .breadcrumbs__link:hover {
    color: inherit;
    text-decoration: underline !important; }

.breadcrumbs__entry {
  display: inline-block; }

.breadcrumb__entry__row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  color: #0000008a; }

.breadcrumbs__currentPage {
  font-weight: 500; }

.breadcrumbs__icon {
  margin-right: 10px;
  margin-top: -3px; }

.breadcrumbs__separator {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.5rem; }

.tabs__container {
  display: -webkit-flex;
  display: flex; }

.tabs__content {
  display: -webkit-flex;
  display: flex; }

.tabs__tab {
  color: inherit;
  text-decoration: none;
  height: 100%;
  padding: calc(1.5*var(--gutter)) calc(3.5*var(--gutter));
  -webkit-align-self: center;
          align-self: center;
  font-weight: 500;
  color: black;
  box-sizing: border-box; }
  .tabs__tab:hover {
    color: var(--linkColor); }
  .tabs__tab.active {
    color: var(--linkColor);
    border-bottom: 3px solid var(--linkColor); }

.fileDrop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  width: 100%;
  color: var(--textColor--dark);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #dcdcdc;
  border: 2px dashed #8a8a8a;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  outline: none; }
  .fileDrop.drag {
    background-color: #c6dbe2 !important;
    border: 2px dashed #92afc0 !important; }
  .fileDrop.success {
    background-color: #c6e2c7;
    border: 2px dashed #92c093; }
  .fileDrop.error {
    background-color: #e2c6c6;
    border: 2px dashed #df9e9e; }

.fileDrop__container {
  text-align: center;
  font-size: 20px;
  padding: 40px 0; }

.fileDrop__text {
  font-weight: 500; }

.fileDrop__fileName {
  font-size: 18px; }

